import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TableEchelons } from "./TableEchelons";

import { Button } from "@mui/material";
import {
  COULEUR_INDEMNITE_RESIDENCE,
  COULEUR_SALAIRE,
  COULEUR_SFT,
} from "../config";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as Tooltip_chartjs,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { calculDetailSalaire, calculSFT } from "../services/utils";
import { ArrowForward, ArrowBack } from "@mui/icons-material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip_chartjs,
  Legend
);

const SimCar = () => {
  const navigate = useNavigate();
  const chartRef = useRef();
  const [simCarData, setSimCarData] = useState(null);

  useEffect(() => {
    const storedData = localStorage.getItem('simCarData');
    if (storedData) {
      setSimCarData(JSON.parse(storedData));
    } else {
      navigate("/simulateur-carriere");
    }
  }, [navigate]);

  if (!simCarData) {
    return <div>Erreur lors du chargement des données</div>;
  }
  // Recuperation d'un tableau contenant les indices majores selon l'echelon
  let listeIndiceMajore = simCarData.echelons.map(e => Number(e.indice_majore));

  // salaire = indice majore * point d'indice + indemnite residence + supplement familial
  let detailSalaire = calculDetailSalaire(listeIndiceMajore, simCarData.userVille, simCarData.nbEnfants);

  // React Chart Js Setup
  let labels = simCarData.echelons.map(e => e.libelle);

  const data = {
    labels,
    datasets: [
      {
        label: "Salaire de base",
        data: detailSalaire.listeSalaireBase,
        backgroundColor: COULEUR_SALAIRE,
        stack: "Stack 0",
      },
      {
        label: "Indemnité de résidence",
        data: detailSalaire.listeIndemniteResidence,
        backgroundColor: COULEUR_INDEMNITE_RESIDENCE,
        stack: "Stack 0",
      },
      {
        label: "Supplément familial de traitement",
        data: detailSalaire.listeSupplementFamilial,
        backgroundColor: COULEUR_SFT,
        stack: "Stack 0",
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: simCarData.userGrade?.libelle,
        font: {
          size: 24,
        },
        color: "black",
      },
      legend: {
        labels: {
          font: {
            size: 14,
          },
        },
      },
    },
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  console.log(calculSFT(461, 4));

  const handleNavigateToEvolutionCarriere = () => {
    const evolutionData = {
      codeGrade: simCarData.userGrade.code_grade,
      echelons: simCarData.echelons,
      currentCodeEchelon: simCarData.choixEchelons,
    };
    localStorage.setItem('evolutionCarriereData', JSON.stringify(evolutionData));
    navigate("/simulateur-carriere/evolution-carriere", { replace: false });
  };

  return (
    <div className="grid grid-cols-12 h-full my-4 ">
      <div className="col-span-1 flex justify-center items-center">
        <Button
          className="w-full h-full flex flex-col mx-auto"
          style={{textTransform: 'none'}} 
          onClick={() => navigate("/simulateur-carriere", { replace: false })}
        >
          <ArrowBack fontSize="large" />
          <span>Revenir au formulaire</span>
        </Button>
      </div>

      <div className="col-span-10 w-10/12 m-auto flex justify-center items-center flex-col">
        <div className="wrapper-bar-chart w-10/12 responsive-hide">
          <Bar id="bar-chart" options={options} data={data} ref={chartRef} />
        </div>

        <TableEchelons
          echelons={simCarData.echelons}
          choixEchelons={simCarData.choixEchelons}
          detailSalaire={detailSalaire}
          libelleGrade={simCarData.userGrade.libelle || ""}
        />

        {/* <div className="export">
          <Button onClick={exportChart}>Exporter le graphique</Button>
        </div> */}

        <p className="italic text-gray-500 mt-4">
          * Les simulations proposées par cet outil sont purement indicatives
          et n’engagent pas l’État. Elles ne sont par conséquent pas opposables.
        </p>
      </div>

      <div className="col-span-1">
        {simCarData.toliniExist && (
          <Button
            className="w-full h-full flex flex-col mx-auto"
            style={{textTransform: 'none'}} 
            onClick={handleNavigateToEvolutionCarriere}
          >
            <ArrowForward fontSize="large" />
            <span>Évolution de carrière</span>
          </Button>
        )}
      </div>
    </div>
  );
};

export { SimCar };
