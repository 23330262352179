import API from "./api";
import { DepartementsType } from "./dataType";

class DepartementsService {

    getDepartements() {
        return API.get<DepartementsType[]>(`/departements`);
    }
}

export default new DepartementsService();