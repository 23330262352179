import axios, { AxiosInstance } from "axios";
import { Observable, defer } from "rxjs";
import { map } from 'rxjs/operators';
import { API_URL } from "../config";
import {
  EchelonsType,
  GradesType,
  MinisteresType,
  VillesType,
  DepartementsType,
  isToliniType,
} from "./dataType";

import { getIndiceFromLetter } from "./utils";
class API_generator {
  ApiInstance: AxiosInstance;

  constructor() {
    this.ApiInstance = axios.create({
      baseURL: API_URL,
    });
  }

  getAxiosInstance() {
    return this.ApiInstance
  }

  get = <T>(url: string, queryParams?: object): Observable<T> => {
    return defer(() => this.ApiInstance.get<T>(url, { params: queryParams }))
      .pipe(map(result => result.data));
  };

  post = <T>(url: string, body: object, queryParams?: object): Observable<T | void> => {
    return defer(() => this.ApiInstance.post<T>(url, body, { params: queryParams }))
      .pipe(map(result => result.data));
  };

  put = <T>(url: string, body: object, queryParams?: object): Observable<T | void> => {
    return defer(() => this.ApiInstance.put<T>(url, body, { params: queryParams }))
      .pipe(map(result => result.data));
  };

  patch = <T>(url: string, body: object, queryParams?: object): Observable<T | void> => {
    return defer(() => this.ApiInstance.patch<T>(url, body, { params: queryParams }))
      .pipe(map(result => result.data));
  };

  deleteR = <T>(url: string, id: number): Observable<T | void> => {
    return defer(() => (this.ApiInstance.delete(`${url}/${id}`)))
      .pipe(map(result => result.data)
      );
  };

  // getGrades(code_ministere: string) {
  //   return this.ApiInstance.get<GradesType[]>(`/grades/${code_ministere}`).then(
  //     (res) => {
  //       // console.log(res.data)
  //       return res.data.sort((a, b) => (a.libelle > b.libelle) ? 1 : -1)
  //     }
  //   );
  // }

  // getEchelons(code_grade: string) {
  //   return this.ApiInstance.get<EchelonsType[]>(`/echelons/${code_grade}`).then(
  //     (res) => {
  //       let result = res.data;
  //       // cas indice majore hors echelon
  //       result.forEach((r) => {
  //         r.indice_majore = getIndiceFromLetter(r.indice_majore);
  //       });
  //       result = result.sort(
  //         (a, b) => Number(a.indice_majore) - Number(b.indice_majore)
  //       );
  //       return result;
  //     }
  //   );
  // }

  // getDepartements() {
  //   return this.ApiInstance.get<DepartementsType[]>("/departements").then(
  //     (res) => {
  //       return res.data;
  //     }
  //   );
  // }

  // getVilles(idDepartement: string) {
  //   return this.ApiInstance.get<VillesType[]>(`/villes/${idDepartement}`).then(
  //     (res) => {
  //       // console.log(res.data)
  //       return res.data;
  //     }
  //   );
  // }

  // getTolini(idGrades: string) {
  //   return this.ApiInstance.get<isToliniType>(`/tolini/exist/${idGrades}`).then(
  //     (res) => {
  //       let result = res.data;
  //       return result.isTolini;
  //     }
  //   );
  // }
}

export default new API_generator();
