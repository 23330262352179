import API from "./api";
import {CorpsType} from "./dataType";

class CorpsService {

    getCorps() {
        return API.get<CorpsType[]>("/corps");
    }
}

export default new CorpsService();
