import React, { useState } from "react";
import {
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableRow,
  Switch,
  FormGroup,
  FormControlLabel,
  Button,
} from "@mui/material";
import { detailSalaireType, EchelonsType } from "../services/dataType";
import { dataToCSV } from "../services/utils";
import DownloadIcon from "@mui/icons-material/Download";

interface tableEchelonsProps {
  echelons: EchelonsType[] | [];
  choixEchelons: string;
  detailSalaire: detailSalaireType;
  libelleGrade: string;
}

const sup = (libelle: string) => {
  return libelle.replace(/(er|ème)/, '<sup>$1</sup>')
}

const TableEchelons = (props: tableEchelonsProps) => {
  const [showDetails, setShowDetails] = useState(false);
  const [showArrondi, setShowArrondi] = useState(false);

  const triggerShowDetails = () => {
    setShowDetails(!showDetails);
  };

  const triggerShowArrondi = () => {
    setShowArrondi(!showArrondi);
  };

  // Recuperation de tous les echelons suivants
  const indexCurrentEchelons = props.echelons.findIndex(
    (e: EchelonsType) => e.code_echelon.toString() === props.choixEchelons
  );
  const suivEchelons = props.echelons.slice(indexCurrentEchelons);
  // const prevEchelons = props.echelons.slice(0, indexCurrentEchelons);

  // Calcul du temps pour atteindre le dernier echelon
  let nbJours = 0;
  suivEchelons.forEach((e) => (nbJours += Number(e.duree)));

  const exportCSV = () => {
    // console.log(props.echelons);
    // console.log(props.detailSalaire);

    const result = dataToCSV(props.echelons, props.detailSalaire);

    const element = document.createElement("a");
    const file = new Blob([result], {
      type: "text/csv;charset=utf8",
    });
    element.href = URL.createObjectURL(file);
    element.download =
      props.libelleGrade.replaceAll(" ", "_") + "_simulateur_carriere.csv";
    document.body.appendChild(element);
    element.click();
  };

  return (
    <div className="flex justify-center items-center m-4 w-full flex-col">
      <div className="header w-full grid grid-cols-12">
        <div className="fake col-span-3"></div>
        <div className="col-span-6 flex justify-center items-center">
          <h3 className="font-bold text-2xl">Grille de salaires</h3>
        </div>

        <div className="options col-span-3">
          <FormGroup className="flex justify-center items-end">
            <FormControlLabel
              control={
                <Switch size="small" onChange={triggerShowDetails}></Switch>
              }
              label="Détails"
              labelPlacement="start"
            ></FormControlLabel>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  onChange={triggerShowArrondi}
                  defaultChecked
                ></Switch>
              }
              label="Arrondi"
              labelPlacement="start"
            ></FormControlLabel>
          </FormGroup>
        </div>
      </div>

      <TableContainer className="border-2 rounded custom-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <p className="text-center font-bold text-base">Echelon</p>
              </TableCell>
              {props.echelons.map((e, index) => (
                <TableCell
                  key={index}
                  className="flex items-center justify-center"
                >
                  <p className=" font-bold text-base" dangerouslySetInnerHTML={{ __html: e.libelle.replace(/(er|ère|ème)/g, '<sup>$1</sup>') }}>
                  </p>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className="hover:bg-gray-200">
              <TableCell>
                <p className="text-center font-bold text-base">Durée</p>
              </TableCell>
              {props.echelons.map((e, index) => (
                <TableCell key={index}>
                  <p>
                    {
                      e.duree === "0000" || e.duree === "" ? 
                      ""
                      :
                      String(Number(e.duree.slice(0, 2))) + " an"
                      + (Number(e.duree.slice(0, 2)) <= 1 ? " " : "s")
                      + (Number(e.duree.slice(2, 4)) === 0 ? "" : (" - " + String(Number(e.duree.slice(2, 4))) + " mois"))
                    }
                    
                  </p>
                </TableCell>
              ))}
            </TableRow>

            {showDetails && (
              <>
                <TableRow className="hover:bg-gray-200">
                  <TableCell>
                    <p className="text-center font-bold text-base">
                      Indice brut
                    </p>
                  </TableCell>
                  {props.echelons.map((s, index) => (
                      <TableCell key={index}>
                        <p>{Number(s.indice_brut)}</p>
                      </TableCell>
                  ))}
                </TableRow>
                <TableRow className="hover:bg-gray-200">
                  <TableCell>
                    <p className="text-center font-bold text-base">
                      Indice majoré
                    </p>
                  </TableCell>
                  {props.echelons.map((s, index) => (
                      <TableCell key={index}>
                        <p>{Number(s.indice_majore)}</p>
                      </TableCell>
                  ))}
                </TableRow>
                <TableRow className="hover:bg-gray-200">
                  <TableCell>
                    <p className="text-center font-bold text-base">
                      Salaire de base (brut mensuel)
                    </p>
                  </TableCell>
                  {props.detailSalaire.listeSalaireBase.map((s, index) => (
                    <TableCell key={index}>
                      <p>{showArrondi ? s.toFixed(2).replace(".", ",") : Math.round(s)} €</p>
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow className="hover:bg-gray-200">
                  <TableCell>
                    <p className="text-center font-bold text-base">
                      Supplément familial de traitement
                    </p>
                  </TableCell>
                  {props.detailSalaire.listeSupplementFamilial.map(
                    (s, index) => (
                      <TableCell key={index}>
                        <p>{showArrondi ? s.toFixed(2).replace(".", ",") : Math.round(s)} €</p>
                      </TableCell>
                    )
                  )}
                </TableRow>
                <TableRow className="hover:bg-gray-200">
                  <TableCell>
                    <p className="text-center font-bold text-base">
                      Indemnité de résidence
                    </p>
                  </TableCell>
                  {props.detailSalaire.listeIndemniteResidence.map(
                    (s, index) => (
                      <TableCell key={index}>
                        <p>{showArrondi ? s.toFixed(2).replace(".", ",") : Math.round(s)} €</p>
                      </TableCell>
                    )
                  )}
                </TableRow>
              </>
            )}

            <TableRow className="hover:bg-gray-200">
              <TableCell>
                <p className="text-center font-bold text-base">Salaire total (brut mensuel)</p>
              </TableCell>

              {props.detailSalaire.listeSalaireTotal.map((s, index) => (
                <TableCell key={index}>
                  <p>{showArrondi ? s.toFixed(2).replace(".", ",") : Math.round(s)} €</p>
                </TableCell>
              ))}
            </TableRow>
          </TableBody>

          <caption className="mx-1 font-bold text-base">
            <div className="flex-wrapper flex items-center">
              <div className="">
                <Button onClick={exportCSV}>
                  <DownloadIcon></DownloadIcon>
                </Button>
              </div>
            </div>
          </caption>
        </Table>
      </TableContainer>
    </div>
  );
};

export { TableEchelons };
