import API from "./api";
import { VillesType } from "./dataType";

class VillesService {

    getVilles(idDepartement: string) {
        return API.get<VillesType[]>(`/villes/${idDepartement}`);
    }
}

export default new VillesService();