import {AxiosInstance} from "axios";
import {isToliniType,} from "./dataType";
import API from "./api";
import {map} from "rxjs/operators";

const sortValues = (x: any, y: any) => {
  return x > y ? 1 : x < y ? -1 : 0; 
};

class ToliniService {
  api: AxiosInstance;

  constructor() {
    this.api = API.getAxiosInstance();
  }

  async getToliniData(code_grade_origine: string, code_echelon_origine: string) {
    return await this.api.get(`/parcours?grade=${code_grade_origine}&echelon=${code_echelon_origine}`);
  }

  getTolini(idGrades: string) {
    return (API.get<isToliniType>(`/tolini/exist/${idGrades}`)).pipe(map(res => {
      return res.isTolini;
    }));
  }
}


export const toliniService = new ToliniService()
