import { map } from "rxjs/operators";
import API from "./api";
import { EchelonsType } from "./dataType";
import { getIndiceFromLetter } from "./utils";

class EchelonsService {

    getEchelons(code_grade: string) {
        return (API.get<EchelonsType[]>(`/echelons/${code_grade}`)).pipe(map(res => {
            let result = res;
            // cas indice majore hors echelon
            // result.forEach((r) => {
            //   r.indice_majore = getIndiceFromLetter(r.indice_majore);
            // });
            result = result.sort(
              (a, b) => Number(a.indice_majore) - Number(b.indice_majore)
            );
            return result;
        }));

    }
}

export default new EchelonsService();