import { map } from "rxjs/operators";
import API from "./api";
import { GradesType } from "./dataType";

class GradesService {

    getGrades(code_ministere: string) {
        return (API.get<GradesType[]>(`/grades/${code_ministere}`)).pipe(map(res => res.sort((a, b) => (a.libelle > b.libelle) ? 1 : -1)));
    }

    getGradesCorps(code_corps: string) {
        return (API.get<GradesType[]>(`/grades/corps/${code_corps}`)).pipe(map(res => res.sort((a, b) => (a.libelle > b.libelle) ? 1 : -1)));
    }
}

export default new GradesService();