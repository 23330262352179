import React from "react";

const Footer = () => {
  return (
    <footer className="flex justify-center items-center bg-gray-200">
      <div className="flex footer justify-center items-center">
        <div className="flex footer items-center ml-20">
          
          <p className="mr-2 text-xl text-gray-500 font-sans whitespace-nowrap">Développé par le</p>
          
          <a href="https://www.economie.gouv.fr/cisirh">
            <img
              src={`${process.env.PUBLIC_URL}/assets/logo_cisirh.png`}
              alt="Acceuil"
              className="img_logo w-18 h-14"
            />
          </a>

          <a href="https://www.fonction-publique.gouv.fr/la-dgafp">
            <img
              src={`${process.env.PUBLIC_URL}/assets/logo_ministere_transformation.png`}
              alt="Acceuil"
              className="img_logo w-50 h-24 ml-4"
            />
          </a>
        </div>

        <a href="https://nexus.cisirh.rie.gouv.fr/repository/RGAA/CISIRH/d%C3%A9claration%20d'accessibilit%C3%A9%20du%20Simulateur%20de%20carri%C3%A8re" 
          target="_blank" className="w-full mx-20 text-xl text-blue-500 hover:underline">
          Accessibilité : non conforme.
        </a>
      </div>
      
    </footer>
  );
};

export { Footer };