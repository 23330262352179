import { ArrowBack } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Xarrow, { Xwrapper } from "react-xarrows";
import "../index.css";
import { toliniService } from "../services/tolini.service";
import {API_URL} from "../config";
import { log } from "console";

const EvolutionCarriere = () => {
  const navigate = useNavigate();
  const [tolini, setTolini] = useState<any>(null);
  const [htmlContent, setHtmlContent] = useState('');
  const [evolutionData, setEvolutionData] = useState(null);

  useEffect(() => {
    const storedData = localStorage.getItem('evolutionCarriereData');
    if (storedData) {
      const data = JSON.parse(storedData);
      setEvolutionData(data);  
      if (data.codeGrade && data.currentCodeEchelon) {
        toliniService
          .getToliniData(data.codeGrade, data.currentCodeEchelon)
          .then((res) => {
            console.log("getToliniData ", res);
            setHtmlContent(res.data);
          })
          .catch((error) => {
            console.error("Erreur lors de la récupération des données :", error);
          });
      } else {
        console.warn("Les valeurs codeGrade et currentCodeEchelon ne sont pas définies.");
      }

    } else {
      navigate("/simulateur-carriere"); 
    } 
  }, [navigate]);

  if (!evolutionData) {
    return <div>Erreur lors du chargement des données</div>;
  }

  console.log("EvolutionCarriere debut ", tolini);


  return (
    <div className="my-4 h-full ev-carr">
      <div className="col-span-1 flex justify-center items-center h-full carr-retour">
        <Button
          className="w-full h-full flex flex-col mx-auto carr-btn"
          style={{textTransform: 'none'}}
          onClick={() =>
            navigate("/simulateur-carriere/chart", { replace: false })
          }
        >
          <ArrowBack fontSize="large" />
          <span>Revenir en arrière</span>
        </Button>
      </div>
      <div className="flex justify-center items-center flex-col w-full">
        <div
            className="col-span-10 flex justify-between evolution-carriere drop-shadow-2xl rounded-lg -mb-24">
            <Xwrapper>
            {htmlContent &&
                <iframe width={"100%"} height={"1000px"} src={`${API_URL}/parcours?grade=${evolutionData.codeGrade}&echelon=${evolutionData.currentCodeEchelon}`} />
            }
            </Xwrapper>
        </div>
          <div>
            <p className="italic text-gray-500">
              * Les simulations proposées par cet outil sont purement indicatives
              et n’engagent pas l’État. Elles ne sont par conséquent pas opposables.
            </p>
          </div>
      </div>
      <div className="menu col-span-1"></div>
    </div>
  );
};

export { EvolutionCarriere };
