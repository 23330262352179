import API from "./api";
import { MinisteresType } from "./dataType";

class MinisteresService {

    getMinisteres() {
        return API.get<MinisteresType[]>("/ministeres");
    }

    getMinisteresByCorps(choixCorps: string) {
        return API.get<MinisteresType[]>(`/ministeres/corps/${choixCorps}`);
    }
}

export default new MinisteresService();
